import React from "react";
import {Popup} from "react-leaflet";

// markup
const MapPopup = ({title, name, description, url}) => {

    return (
        <Popup className="rounded-0 text-lightblack">
            <div>
                <div className="text-base text-center uppercase mb-1.5 font-medium font-heading">{title}</div>
                {
                    name &&  <div className="text-lg text-center mb-2.5 font-bold uppercase">{name}</div>
                }
                {
                    description &&  <p className="font-heading uppercase mb-1.5 text-center text-sm">{description}</p>
                }
                {
                    url && url.external ?
                        <a className="!text-lightblack underline text-center block" rel="noopener noreferrer" target="_blank" href={url.external ? url.external : "/"} >{url.name ? url.name : "Plus d'infos"}</a>
                        : null
                }
            </div>
        </Popup>
    );
};

export default MapPopup;
