import React, { useEffect, useRef, useState } from 'react'
import MapFilterIcon from './MapFilterIcons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import childsHeight from '../../Utils/childsHeight'

const MapFilterItem = ({ callback, title, type, checked = false, children, filterNav, isOpenFilter }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdown = useRef()

  /**
     * Do something when the filter is checked
     * @param e event
     */
  const handleOnChange = (e) => {
    const isChecked = e.currentTarget.checked
    callback(isChecked, title)
  }

  /**
     * Do something when dropdown is clicked
     * @param e event
     */
  const handleDropdownClick = (e) => {
    e.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    if (isDropdownOpen) {
      /*
            * If filter navigation is open add the opened dropdown height to it
            * else close the dropdown
            */
      if (!isOpenFilter) {
        setIsDropdownOpen(false)
      } else {
        dropdown.current.style.height = childsHeight(dropdown.current, 8) + 'px'
        filterNav.current.style.height = childsHeight(filterNav.current, 0, childsHeight(dropdown.current, 8)) + 'px'
      }
    } else {
      /*
            * Close the dropdown
            * and if the filter navigation is open remove dropdown height to filter navigation height
            */
      dropdown.current.style.height = '0'
      if (isOpenFilter) {
        filterNav.current.style.height = childsHeight(filterNav.current, 0, -childsHeight(dropdown.current, 8)) + 'px'
      }
    }
  }, [isDropdownOpen, isOpenFilter, filterNav])

  return (
        <div className="p-2">
            <div className="flex items-center justify-between font-obviously font-semibold uppercase text-black">
                {children.props.children.length > 0
                  ? <button className="uppercase text-left" onClick={(e) => { handleDropdownClick(e) }}>
                        <label className="font-obviously font-semibold flex items-center w-full mb-1 pr-6" htmlFor={title}>
                            <MapFilterIcon type={type}/>{title}
                            <FontAwesomeIcon className="ml-1 text-[#bfbfbf]" icon={faChevronDown} />
                        </label>
                    </button>
                  : <div className='flex w-full max-w-[300px]'>
                            <MapFilterIcon type={type}/>{title}
                    </div>
                }

                <input className={`appearance-none w-[15px] p-[6.5px] border h-[15px] border-[grey] ${checked ? 'bg-[grey]' : 'bg-[transparent]'} rounded-full`} checked={checked} onChange={callback ? (e) => handleOnChange(e) : () => false} id={title} type="checkbox"/>
            </div>
            <div ref={dropdown} className={'transition-all overflow-hidden w-[300px]'}>
                {children}
            </div>

        </div>

  )
}

export default MapFilterItem
