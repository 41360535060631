import React from 'react'

// markup
const InfosHeader = ({ title, htmlContent, title2 }) => {
  return (
        <div>
          <span className="relative -translate-y-48 block" id="info_content"/>
          <div className="content container flex flex-col md:flex-row md:items-end md:mb-10 mb-8 mt-6">
              <h1 className="max-w-[70%] leading-none">{title}<br/>{title2}</h1>
              <div className="flex-grow md:ml-12 align-right">{htmlContent}</div>
          </div>
        </div>

  )
}

export default InfosHeader
